import { render, staticRenderFns } from "./AddToProject.vue?vue&type=template&id=d64028be&scoped=true&"
import script from "./AddToProject.vue?vue&type=script&lang=js&"
export * from "./AddToProject.vue?vue&type=script&lang=js&"
import style0 from "./AddToProject.vue?vue&type=style&index=0&id=d64028be&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d64028be",
  null
  
)

export default component.exports