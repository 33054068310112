var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "add-projects"
  }, [_c('div', {
    staticClass: "add-projects__title"
  }, [_c('PageTitle', {
    attrs: {
      "text": "Добавление специальности в проект"
    }
  }), _c('div', {
    staticClass: "add-projects__title-options"
  }, [_c('router-link', {
    staticClass: "el-button",
    attrs: {
      "to": {
        name: 'Professions'
      }
    }
  }, [_vm._v(" Отмена ")]), _c('el-button', {
    attrs: {
      "type": "primary",
      "disabled": !_vm.selectedProject
    },
    domProps: {
      "textContent": _vm._s('Нанять')
    },
    on: {
      "click": _vm.chooseProject
    }
  })], 1)], 1), _c('div', {
    staticClass: "add-projects__table"
  }, [_c('ProjectsTable', {
    attrs: {
      "for-speciality": ""
    },
    on: {
      "changed": _vm.changedProject
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }