<template>
  <div class="add-projects">
    <div class="add-projects__title">
      <PageTitle text="Добавление специальности в проект" />
      <div class="add-projects__title-options">
        <router-link :to="{ name: 'Professions' }" class="el-button">
          Отмена
        </router-link>
        <el-button
          type="primary"
          :disabled="!selectedProject"
          @click="chooseProject"
          v-text="'Нанять'"
        />
      </div>
    </div>
    <div class="add-projects__table">
      <ProjectsTable for-speciality @changed="changedProject" />
    </div>
  </div>
</template>

<script>
import PageTitle from '../components/elements/PageTitle'
import ProjectsTable from '../components/blocks/ProjectsTable'

export default {
  name: 'AddToProject',
  components: {
    PageTitle,
    ProjectsTable,
  },
  data() {
    return {
      selectedProject: '',
    }
  },
  methods: {
    changedProject(val) {
      this.selectedProject = val
    },
    chooseProject() {
      this.$router.push({ path: `/projects/${this.selectedProject}?type=vacancy&for=${this.$route.query.for}` })
    },
  },
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/helpers/variables';
@import 'src/assets/styles/helpers/mixin';

.add-projects {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.6rem;

    &-options {
      display: flex;
    }

    .el-button {
      width: 20.2rem;
      margin-left: 2.4rem;
      text-decoration: none;
    }
  }

  &__table {
    .cell {
      width: 90%;
    }

    &-status {
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      background: $error;

      &.active {
        background: $active;
      }
    }

    &-employees {
      display: flex;
      font-size: 1rem;
      line-height: 1.4rem;

      & > div {
        width: 3.6rem;
        height: 2.6rem;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__hired {
        background: rgba(104, 218, 136, 0.15);
        border: 1px solid $active;
        color: $active;
      }

      &__needed {
        background: rgba(221, 74, 100, 0.15);
        border: 1px solid $error;
        color: $error;
        margin-left: 0.8rem;
      }
    }

    &-actions {
      display: flex;
      align-items: center;

      &__chat {
        margin-right: 2rem;
        margin-top: 0.7rem;
      }
    }

    .el-table th {
      height: 5.8rem;
      padding: 0;
    }

    &-item {
      &--bold {
        font-weight: 600;
        color: $text;
      }
    }
  }

  &__options {
    border-radius: 2px;
    border: none;
    box-shadow: 6px 6px 32px rgba(0, 0, 0, 0.08);

    .el-dropdown-menu__item {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: $text;
      padding: 0.5rem 2.2rem;

      &.removed {
        color: $error;
      }

      img {
        margin-right: 0.8rem;
      }
    }
  }
}

@media (max-width: $screen-sm-max){
	.add-projects{
		.page-title{
			font-size: 20px;
			line-height: 24px;
			width: 100%;
		}
	}
	.add-projects__title{
		flex-wrap: wrap;
	}
	.add-projects__title-options{
		margin-top: 12px;
		display: flex;
		width: 100%;

		.el-button{
			width: 40%;
			flex-grow: 1;

			&:first-child{
				margin-left: 0;
			}
		}
	}
}
</style>
